import React from "react"

import Accordion from "@/components/Accordion"
import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import { StaticImage } from "gatsby-plugin-image"

type PageProps = {
  data: any
}

const PrivacyNotice: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/cont.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Privacy"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading uppercase">
            Privacy Notice
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Privacy Notice" />
    <div className="container">
      <div className="flex justify-center">
        <div className="max-w-[700px] py-14">
          <h3 className="text-3xl pb-4">How we use Alumni information</h3>

          <p>
            The RBAI Foundation is the hub for communications with past pupils
            and friends of the School. This notice describes what information
            the School holds about you, why we hold it and how we use it.
          </p>

          <Accordion
            rows={[
              {
                title: "WHAT IS DATA?",
                content: (
                  <>
                    <p>
                      Data is any information which relates to you - the
                      individual. Personal data is information which can
                      identify you. An example of personal data is your full
                      name, address and date of birth.
                    </p>
                  </>
                ),
              },
              {
                title: "WHY WE COLLECT AND USE YOUR DATA",
                content: (
                  <>
                    <p>
                      Processing your data helps us to maintain your association
                      with the School. We process your data in pursuit of our
                      legitimate interests or because we hold your consent for
                      specific activities.  It enables us to communicate and
                      engage with you, updating you regarding school news,
                      updates, events and fundraising.
                    </p>
                    <p>
                      <strong>
                        The information which we collect, hold and share
                        includes the following. We do not necessarily hold all
                        of this information for every alumnus.
                      </strong>
                    </p>
                    <ul className="list-square pl-4">
                      <li>Personal information such as name, title, date of birth
                      and gender</li>
                      <li>Contact information such as home address, business,
                      email, and telephone</li>
                      <li>Education information and your connections with the
                      School including clubs, societies and affiliations</li>
                      <li>Employment/career details, career biographies or alumni
                      profiles</li>
                      <li>Video and photographic images such as, sports team
                      photographs, school visits/ events records</li>
                      <li>Information publicly shared on social media, eg LinkedIn,
                      Twitter, Facebook etc</li>
                      <li>Health related information if attending an event e.g.
                      access, dietary requirements</li>
                      <li>Family and partner/spousal name only</li>
                      <li>Relationships with other alumni or people associated with
                      the School</li>
                      <li>Your interests such as volunteering activity and
                      philanthropic affiliations</li>
                      <li>Donation history to the School</li>
                      <li>Bank account details (for donors who support regular
                      giving)</li>
                      <li>Records of communications with the RBAI Foundation
                      (formerly DARO) and the School</li>
                      <li>Event attendance</li>
                      <li>Assessment of capacity to donate – prosperity assessment</li>
                      <li>Motivation for giving</li>
                      <li>Photographs</li>
                      <li>Identification numbers on our alumni database</li>
                    </ul>
                    <p>
                      Some of the information we process, such as disability
                      details for access to an event, is sensitive personal
                      information that we will not process unless we have your
                      explicit consent, or we are legally required to do so.
                    </p>
                  </>
                ),
              },
              {
                title: "WHERE WE COLLECT YOUR DATA FROM",
                content: (
                  <>
                    <p>
                      We collect your data from a number of sources but
                      primarily information is collected directly from you. We
                      may also collect data from:
                    </p>
                    <ul className="list-square pl-4">
                      <li>staff, supporters and your peers who make introductions</li>
                      <li>research into publicly available sources for profiling,
                      address updates and analytics, for example, LinkedIn,
                      Companies House, and other similar sources</li>
                      <li>carefully selected third parties that help keep our data
                      up-to-date or to help enrich your data e.g. screening
                      companies</li>
                      <li>from a third party whom you have interacted with on
                      behalf of the Foundation, for example: JustGiving and
                      Eventbrite</li>
                    </ul>
                  </>
                ),
              },
              {
                title: "YOUR DATA: ACCURACY AND SHARING",
                content: (
                  <>
                    <p>
                      <strong>Accuracy of Data</strong>
                      <br />
                      We always aim to keep your data accurate and up to date.
                      Data that is inaccurate can be amended on request. Please
                      notify us if your personal details change or if data we
                      hold about you is inaccurate.
                      <br />
                      Depending on your privacy settings, we may use social
                      media sites like LinkedIn, Facebook and Twitter and/or
                      other data enrichment services to validate your data and
                      help us maintain our communication with you.
                    </p>
                    <p>
                      <strong>Holding Data</strong>
                      <br />
                      We create and hold your data both electronically and on
                      paper. The RBAI Foundation considers its relationship with
                      alumni, supporters and potential supporters as ongoing and
                      as such will maintain a record until you tell us you no
                      longer wish to keep in touch or we have a requirement to
                      delete information.
                    </p>
                    <p>
                      <strong>Sharing your Data</strong>
                      <br />
                      We will never sell your data to third parties and we will
                      only share your data to legitimately maintain your
                      relationship with the School. We sometimes work with
                      external providers to develop and improve the data we
                      store. These include data enrichment and screening
                      companies.
                    </p>
                    <p>
                      If you are a member of the BOIA your data is shared
                      between the two organisations as the Foundation maintains
                      the BOIA database and carries out some administration on
                      behalf of BOIA.
                    </p>
                    <p>
                      If you have given a donation, we may share data to process
                      your donation with your bank, under your instruction.
                      <br />
                      We may have to disclose your data if required to do so by
                      law in order to comply with a legal obligation, for
                      example, to protect or defend our rights, interests or
                      property.
                    </p>
                  </>
                ),
              },
              {
                title: "SECURITY OF YOUR DATA",
                content: (
                  <>
                    <p>
                      There are strict codes of conduct in place to keep your
                      information safe and staff abide by data protection
                      legislation.
                    </p>
                    <p>
                      We ensure that suitable measures are in place to prevent
                      the unlawful or unauthorised processing and against the
                      accidental loss of, or damage to, your personal
                      information. This includes:
                    </p>
                    <ul className="list-square pl-4">
                      <li>Storing data on a secure, specially designed, alumni
                      relationship management database</li>
                      <li>Training staff in data protection and their
                      responsibilities</li>
                      <li>Collaborating only with reputable companies for data
                      processing services, who comply with data protection
                      legislation and put in place appropriate non-disclosure
                      agreements</li>
                      <li>Ensuring that alternative protection is in place if
                      we work with trusted organisations based outside the
                      European Economic Area (EEA) in countries that do not have
                      comparable data protection laws to the UK</li>
                    </ul>
                  </>
                ),
              },
              {
                title: "CONTROL OVER YOUR DATA",
                content: (
                  <>
                    <p>
                      You can update your data or contact preferences at any
                      time or advise us you wish us to stop contacting you at:
                      www.rbaifoundation.org/contact-preferences or by calling
                      +44(0)28 9024 0461 ext 1239.
                    </p>
                    <p>
                      If you do not wish for us to continue to process your
                      data, we will retain minimal details on our database to
                      ensure your details are not re-added in the future for
                      another purpose or through another data collection route.
                    </p>
                    <p>
                      If you would like to access your personal information or
                      if you have a complaint in relation to the data we hold on
                      you, please make a request in writing to Claire Moore,
                      Bursar, RBAI, College Square East, Belfast BT1 6DL.
                    </p>
                    <p>
                      You will need to be able to provide proof of identity
                      (e.g. full name, address, date of birth). We will provide
                      your information within 30 calendar days of receiving your
                      request.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyNotice
